/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  revmedFulfillmentFeeMinimum: {
    id: 'UpdateFulfillmentSettingsModal.revmedFulfillmentFeeMinimum',
    defaultMessage: 'Must provide a fulfillment fee of at leastzero',
    description:
      'Error message when the fulfillment fee for an item is less than zero'
  },
  revmedFulfillmentFeeMaximum: {
    id: 'UpdateFulfillmentSettingsModal.revmedFulfillmentFeeMaximum',
    defaultMessage:
      'You cannot exceed the maximum fulfillment fee of {max, number, ::currency/USD}',
    description:
      'Error message when the fulfillment fee is greater than the maximum'
  },
  fulfilledByRequired: {
    id: 'UpdateFulfillmentSettingsModal.fulfilledByRequired',
    defaultMessage: 'You must choose who will fulfill this item',
    description: 'Error message when fulfilled by is not selected'
  }
});
