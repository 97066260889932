/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  find,
  get,
  includes,
  isArray,
  isEmpty,
  isPlainObject,
  map,
  omit,
  uniqBy
} from 'lodash';

import { compile } from '../../utils/template';
import setIn from '../../utils/lodash/setIn';
import { hooks, unstable } from '@broadleaf/admin-components/dist';
import {
  CollectionStateHook,
  useCollectionState
} from '@broadleaf/admin-components/dist/collection';
import {
  SelectableType,
  useRefreshEffect
} from '@broadleaf/admin-components/dist/common';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import { lookupComponents } from '@broadleaf/admin-components/dist/form';

const { selectAllItems, selectCurrentPage, selectFilter } = CollectionStateHook;

const Lookup = props => {
  const componentsRef = useRef(defaultComponents(props));
  const {
    Header,
    LookupContainer,
    Modal,
    ModalToggle,
    Select,
    SelectContainer
  } = componentsRef.current;
  const {
    getCommonProps,
    getOptionLabel,
    getOptionValue,
    inputValue,
    isLoading,
    onFocus,
    onInputChange,
    onMenuScrollToBottom,
    isModalOpen,
    onModalClose,
    onModalOpen,
    onModalSelect,
    options,
    selectRef,
    value
  } = useLookup(props);
  return (
    <LookupContainer {...getCommonProps()}>
      <Header {...getCommonProps()}>
        {props.isModalSupport && (
          <ModalToggle
            {...getCommonProps()}
            innerProps={{ onClick: onModalOpen, disabled: props.isDisabled }}
          >
            {props.modalToggleLabel}
          </ModalToggle>
        )}
      </Header>

      <SelectContainer {...getCommonProps()}>
        <Select
          {...getCommonProps()}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          inputValue={inputValue}
          isClearable={props.isClearable}
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          isMulti={props.isMulti}
          isSearchable={props.isSearchable}
          loadingMessage={props.loadingMessage}
          name={props.name}
          noOptionsMessage={props.noOptionsMessage}
          onBlur={props.onBlur}
          onChange={props.onChange}
          onFocus={onFocus}
          onInputChange={onInputChange}
          onKeyDown={props.onKeyDown}
          onMenuScrollToBottom={onMenuScrollToBottom}
          options={options}
          placeholder={props.placeholder}
          selectRef={selectRef}
          value={value}
          // REVMED disable filter options
          filterOption={() => {
            return true;
          }}
        />
      </SelectContainer>

      {props.isModalSupport && isModalOpen && (
        <Modal
          {...getCommonProps()}
          metadata={props.modalMetadata}
          onClose={onModalClose}
          onSelect={onModalSelect}
          value={value}
        />
      )}
    </LookupContainer>
  );
};

function useLookup(props) {
  const selectRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const collectionState = useCollectionState({
    idKey: props.valueKey,
    readConfig: props.endpoint,
    readContextParams: props.contextParams,
    selectable: true,
    selectableType: SelectableType.SINGLE,
    implicitFilters: props.implicitFilters
  });

  const options = map(
    selectAllItems(collectionState.state),
    hotFixStripOptions
  );
  const currentPage = selectCurrentPage(collectionState.state);
  const isLoading = currentPage.isHydrating;
  const currentFilter = selectFilter(collectionState.state, {
    __IS_SELECT__: true,
    type: 'QUERY'
  });
  const inputValue = currentFilter ? currentFilter.value : '';
  const value = getMatchingValue(options, props.value, {
    labelKey: props.labelKey,
    valueKey: props.valueKey
  });

  useRefreshEffect(
    () => {
      // clear out the lookup's value when dependencies change
      selectRef.current && selectRef.current.onChange(null, 'clear');
    },
    props.dependsOn,
    undefined,
    false
  );

  useRefreshEffect(
    () => {
      // refresh collection state when context parameters change
      collectionState.handleReinitialize();
    },
    props.contextParams,
    undefined,
    false
  );

  function getCommonProps() {
    return {
      collectionState,
      contextParams: props.contextParams,
      lookupProps: props
    };
  }

  const onFocus = useEventCallback(
    event => {
      collectionState.handleRead();

      // forward to the prop, if it exists
      props.onFocus && props.onFocus(event);
    },
    [collectionState, props]
  );

  const onInputChange = useEventCallback(
    inputValue => {
      collectionState.actions.setFilters([
        { __IS_SELECT__: true, type: 'QUERY', name: 'q', value: inputValue }
      ]);
    },
    [collectionState.actions]
  );

  const onMenuScrollToBottom = useEventCallback(() => {
    collectionState.handlePageChange(true);
  }, [collectionState]);

  const onModalClose = useEventCallback(() => {
    setIsModalOpen(false);
    props.onModalClose && props.onModalClose();

    collectionState.handleReinitialize();
  }, [collectionState]);

  const onModalOpen = useEventCallback(() => {
    setIsModalOpen(true);
    props.onModalOpen && props.onModalOpen();

    collectionState.handleReinitialize();
  }, [collectionState]);

  const onModalSelect = useEventCallback(
    option => {
      onModalClose();

      if (!props.isMulti) {
        props.onChange(option, 'select-option');
      } else {
        props.onChange(
          uniqBy([...value, option], props.valueKey),
          'select-option'
        );
      }
    },
    [onModalClose, props.onChange, props.isMulti, value, props.valueKey]
  );

  function getOptionLabel(option) {
    const labelKeyOrTemplate = props.labelKey;
    return includes(labelKeyOrTemplate, '$')
      ? compile(labelKeyOrTemplate)({ option })
      : get(option, labelKeyOrTemplate);
  }

  function getOptionValue(option) {
    return get(option, props.valueKey || 'value');
  }

  return {
    getCommonProps,
    getOptionLabel,
    getOptionValue,
    inputValue,
    isLoading,
    isModalOpen,
    onFocus,
    onInputChange,
    onMenuScrollToBottom,
    onModalClose,
    onModalOpen,
    onModalSelect,
    options,
    selectRef,
    value
  };
}

Lookup.propTypes = {
  components: PropTypes.object,
  contextParams: PropTypes.object,
  dependsOn: PropTypes.object,
  endpoint: PropTypes.object.isRequired,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isModalSupport: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  /**
   * The property key for the option's label. It can contain the template to create more complicated labels.
   * For example to display the label like `Name (id)` the template like `${name} - (${id})` can be used.
   */
  labelKey: PropTypes.string,
  loadingMessage: PropTypes.string,
  modalMetadata: PropTypes.object,
  modalToggleLabel: PropTypes.node,
  noOptionsMessage: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onModalClose: PropTypes.func,
  onModalOpen: PropTypes.func,
  placeholder: PropTypes.node,
  valueKey: PropTypes.string,
  value: PropTypes.any,
  implicitFilters: PropTypes.arrayOf(PropTypes.object)
};

Lookup.defaultProps = {
  isClearable: true,
  isModalSupport: false,
  isMulti: false,
  isSearchable: true,
  labelKey: 'label'
};

/**
 * Helper method used for matching the provided `value` prop against the Options
 * loaded from the external endpoint. This ensures that the labeling of the
 * selected value is correct. This also handles the use case where the provided
 * value is not an Option, but the value of an Option.
 *
 * If no matching Option is found for a simple-value type, e.g. value is a string,
 * then a fallback option will be created with the label, "${value}".
 *
 * @param options the current Option set
 * @param value the currently selected value
 * @param labelKey the property key to get the label
 * @param valueKey the property key to get the value
 * @returns {Object|Array|*}
 */
function getMatchingValue(options = [], value, { labelKey, valueKey }) {
  if (isEmpty(value)) {
    return value;
  }

  if (isArray(value)) {
    // [1, 2, 3] ==> [{ value: 1 }, { value: 2 }, { value: 3}]
    return map(value, elem =>
      getMatchingValue(options, elem, { labelKey, valueKey })
    );
  }

  if (isPlainObject(value)) {
    // If we find an option that matches our current value, return that as the current value.
    // This ensures that if an option is provided with outdated labeling that the options label takes precendence.
    const matchingOption = find(
      options,
      option => get(option, valueKey) === get(value, valueKey)
    );
    if (!matchingOption) {
      return {
        ...createFallbackOption(get(value, valueKey), { labelKey, valueKey }),
        ...value
      };
    }
    return matchingOption;
  }

  const matchingOption = find(
    options,
    option => get(option, valueKey) === value
  );
  if (!matchingOption) {
    // if no matching option for our value, return a placeholder option
    return createFallbackOption(value, { labelKey, valueKey });
  }
  return matchingOption;
}

/**
 * Helper method to create a fallback option when there is no matching option.
 */
function createFallbackOption(value, { labelKey, valueKey }) {
  return setIn(
    setIn(
      {
        // extensions might choose to render the labeling different if the item is not found
        __IS_MISSING_DATA__: true
      },
      valueKey,
      value
    ),
    labelKey,
    value
  );
}

/**
 * This is a temporary hot-fix for an issue within `react-select`:
 *
 * https://github.com/JedWatson/react-select/issues/3706
 *
 * This issue involves the assumption that any Option is a Group if it simply
 * has an `options` property. Ideally, this behavior will be configurable as a
 * prop to the `Select`.
 *
 * @param  {Object} option the option to omit "options" for
 * @return {Object}        the option without any "options"
 */
function hotFixStripOptions(option) {
  const itemOptions = get(option, 'options');
  if (itemOptions) {
    return { ...omit(option, ['options']), itemOptions };
  }

  return option;
}

function defaultComponents(props) {
  return { ...lookupComponents, ...props.components };
}

export default Lookup;
export { Lookup };
