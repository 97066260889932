/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { get, includes } from 'lodash';

import log from '@broadleaf/admin-components/dist/common/utils/log';
import { getEndpointByType } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import FulfillmentAction from '@broadleaf/admin-components/dist/oms/components/FulfillmentAction';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import { failurePrintLabel } from '@broadleaf/admin-components/dist/oms/hooks/useFufillmentState/state/fulfillmentActions';
import {
  IMetadata,
  IMetadataAction
} from '@broadleaf/admin-components/dist/types/metadata';
import { IFulfillmentViewLocalState } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';

const logger = log.getLogger(
  'oms.components.FulfillmentView.action-components.PrintShippingLabel'
);

export interface PrintShippingLabelProps {
  actionDefinition: IMetadataAction;
  dispatch: Function;
  metadata: IMetadata;
  state: IFulfillmentViewLocalState;
}

const PrintShippingLabel: FC<PrintShippingLabelProps> = props => {
  const { actionDefinition, dispatch, metadata, state } = props;
  const { attributes = {}, label } = actionDefinition;
  //const reprintActionLabel = attributes['reprintActionLabel'];
  const { allowedStatuses = [] } = attributes as Record<string, any>;
  const displayInFrame = get(attributes, 'displayInFrame', true) === true;
  const frameHeight = get(attributes, 'frameHeight', 600);
  const frameWidth = get(attributes, 'frameWidth', 600);
  const frameDimensions = useMemo(
    () => determineFrameDimensions(frameWidth, frameHeight),
    [frameWidth, frameHeight]
  );
  const statusEndpoint = getEndpointByType(actionDefinition, 'READ_STATUS');
  const purchaseEndpoint = getEndpointByType(
    actionDefinition,
    'PURCHASE_LABEL'
  );
  const reprintEndpoint = getEndpointByType(actionDefinition, 'REPRINT_LABEL');
  const contextParams = useContextParams(metadata, state);
  const [shouldReprint, setShouldReprint] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [printLoading, setPrintLoading] = useState<boolean>(false);
  const [statusChecked, setStatusChecked] = useState<boolean>(false);
  const [canPrintLabel, setCanPrintLabel] = useState<boolean>(false);
  const initiatePrint = useCallback(
    async shouldReprint => {
      const req = state.data;
      let url: string;
      setPrintLoading(true);
      try {
        const endpoint = shouldReprint ? reprintEndpoint : purchaseEndpoint;
        const {
          data: { url: link }
        } = await request(
          { method: 'post', data: req, ...endpoint },
          contextParams
        );
        url = link;
        setShouldReprint(true);
      } catch (err) {
        if (err.cancelled) {
          return;
        }
        logger.error(
          `An error occurred trying to ${
            shouldReprint ? 'reprint' : 'print'
          } shipping label for fulfillment ${contextParams.id}`
        );
        dispatch(failurePrintLabel(err));
      } finally {
        setPrintLoading(false);
      }

      if (!url?.length) {
        return;
      }

      if (displayInFrame) {
        const shippingLabelWindow = window.open(
          '',
          'ShippingLabelFrame',
          `resizable,scrollbars,top=${frameDimensions.top},left=${frameDimensions.left},width=${frameDimensions.width},height=${frameDimensions.height}`
        );
        if (shippingLabelWindow) {
          // create a new document to write the image assuming it should be 4x6 and is a png
          shippingLabelWindow.document.write(
            `<html><head><title>RevMed Shipping Label #${state.data.orderFulfillmentNumber}</title></head>
            <body><img alt="Shipping Label" style='width: 384px' src="${url}"/></body></html>`
          );
          shippingLabelWindow.document.close();
          shippingLabelWindow.focus();
          shippingLabelWindow.onload = () => {
            shippingLabelWindow.print();
          };
        }
        return;
      }

      window.open(url, '_blank', 'noopener,noreferrer');
    },
    [
      contextParams,
      dispatch,
      displayInFrame,
      frameDimensions.height,
      frameDimensions.left,
      frameDimensions.top,
      frameDimensions.width,
      purchaseEndpoint,
      reprintEndpoint,
      state.data
    ]
  );

  useEffect(() => {
    if (statusChecked) {
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const {
          data: { canPrintLabel, hasPrinted }
        } = await request(
          { method: 'post', ...statusEndpoint, data: state.data },
          contextParams
        );
        setCanPrintLabel(canPrintLabel);
        setShouldReprint(hasPrinted);
      } catch (err) {
        if (err.cancelled) {
          return;
        }
        logger.error(
          `An error occurred trying to read the print label status for fulfillment ${contextParams.id}`
        );
      } finally {
        setLoading(false);
        setStatusChecked(true);
      }
    })();
  }, [contextParams, statusEndpoint, statusChecked, state.data]);

  if (
    !canPrintLabel ||
    !includes(allowedStatuses, state.data.status) ||
    state.data.type !== 'SHIP'
  ) {
    return <></>;
  }

  return (
    <>
      <FulfillmentAction
        disabled={state.isFetching || loading || printLoading}
        onClick={() => {
          initiatePrint(shouldReprint);
        }}
      >
        {label}
      </FulfillmentAction>
    </>
  );
};

function determineFrameDimensions(
  width = 600,
  height = 600
): {
  top: number;
  left: number;
  width: number;
  height: number;
} {
  const screenW = window.screen.width;
  const screenH = window.screen.height;

  width = width < 0 ? 600 : width;
  height = height < 0 ? 600 : height;

  const maxW =
    (window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width) * 0.8;
  const maxH =
    (window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height) * 0.8;
  const w = width === 0 || width > maxW ? maxW : width;
  const h = height === 0 || height > maxH ? maxH : height;
  const systemZoom = screenW / window.screen.availWidth;
  const left = (screenW - w) / 2 / systemZoom + window.screenX;
  const top = (screenH - h) / 2 / systemZoom + window.screenY;

  return {
    top,
    left,
    width: w,
    height: h
  };
}

export default PrintShippingLabel;
export { PrintShippingLabel };
