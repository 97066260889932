/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import { getMerchandiseTotal } from '@broadleaf/admin-components/dist/oms/utils/FulfillmentUtils';
import { ICommonMonetaryAmount } from '@broadleaf/admin-components/dist/types/common';
import { IOrderFulfillment } from '@broadleaf/admin-components/dist/types/oms';

export type IOrderFulfillmentSettings = {
  fulfilledByEmail: string;
  fulfillmentOverrideOption: string;
  revMedFulfillmentFee: number;
  revMedFeeAmountOverride: number;
  revMedFeePercentOverride: number;
  maxRevMedFulfillmentFee: number;
};
export function getAttributeAmount(fulfillment, attribute, negate = false) {
  const amount =
    typeof fulfillment.attributes[attribute] === 'number'
      ? fulfillment.attributes[attribute]
      : 0;
  return {
    amount: amount * (negate && amount !== 0 ? -1 : 1),
    currency: fulfillment.currency
  };
}

export function getMaxRevMedFee(
  fulfillment: IOrderFulfillment
): ICommonMonetaryAmount {
  const merchandiseTotal = getMerchandiseTotal(fulfillment);
  const insuranceCost = getAttributeAmount(fulfillment, 'insuranceCost');
  return {
    amount: merchandiseTotal.amount - insuranceCost.amount,
    currency: fulfillment.currency
  };
}

export function getMaxFulfillmentFee(
  fulfillment: IOrderFulfillment,
  revMedFees: ICommonMonetaryAmount = getAttributeAmount(
    fulfillment,
    'revMedFee'
  )
): ICommonMonetaryAmount {
  return {
    amount: getMaxRevMedFee(fulfillment).amount - revMedFees.amount,
    currency: fulfillment.currency
  };
}

export function hasPackingSlipBeenPrinted(
  orderFulfillment: IOrderFulfillment
): boolean {
  return !!orderFulfillment.attributes['printPackingSlipDateTime'];
}

export const getHasSaturdayDelivery = (fg?: IOrderFulfillment): boolean => {
  return (
    getAttributeValue(
      fg?.attributes,
      'saturdayDelivery',
      getAttributeValue(
        fg?.address?.attributes as Record<string, string>,
        'saturdayDelivery',
        'false'
      )
    ) === 'true'
  );
};

export const getShippingAccountType = (fg?: IOrderFulfillment): string => {
  return getAttributeValue(
    fg?.attributes,
    'shippingAccountType',
    getAttributeValue(
      fg?.address?.attributes as Record<string, string>,
      'shippingAccountType',
      undefined
    )
  ) as string;
};

export const getShippingAccountNumber = (fg?: IOrderFulfillment): string => {
  return getAttributeValue(
    fg?.attributes,
    'shippingAccountNumber',
    getAttributeValue(
      fg?.address?.attributes as Record<string, string>,
      'shippingAccountNumber',
      undefined
    )
  ) as string;
};

export const getShippingAccountPostalCode = (
  fg?: IOrderFulfillment
): string => {
  return getAttributeValue(
    fg?.attributes,
    'shippingAccountPostalCode',
    getAttributeValue(
      fg?.address?.attributes as Record<string, string>,
      'shippingAccountPostalCode',
      undefined
    )
  ) as string;
};

export const getCarrierFriendlyName = (fg: IOrderFulfillment) => {
  const type = getShippingAccountType(fg);
  if (type === 'ups') {
    return 'UPS';
  } else {
    return 'FedEx';
  }
};

export const getShippingOptionMessages = (fg?: IOrderFulfillment): string[] => {
  const fulfillmentOption = fg?.fulfillmentOption;
  if (fulfillmentOption && !fulfillmentOption?.name?.startsWith('SHIPENGINE')) {
    return [];
  }

  const shippingOptionMessages = [];
  const shippingAccountType = getShippingAccountType(fg);
  if (shippingAccountType) {
    const shippingAccountNumber = getShippingAccountNumber(fg);
    const carrier = getCarrierFriendlyName(fg);
    shippingOptionMessages.push(
      `Billed to ${carrier} #${shippingAccountNumber}`
    );
  }

  const hasSaturdayDelivery = getHasSaturdayDelivery(fg);
  if (hasSaturdayDelivery) {
    shippingOptionMessages.push(`Saturday Delivery`);
  }
  return shippingOptionMessages;
};

export function getAttributeValue(
  attributes: Record<string, unknown> | Record<string, any>,
  attributeName: string,
  defaultValue: any = undefined
): unknown {
  if (attributes?.[attributeName] !== undefined) {
    if (
      Object.prototype.hasOwnProperty.call(attributes[attributeName], 'value')
    ) {
      return attributes[attributeName]['value'];
    } else {
      return attributes[attributeName];
    }
  }
  return defaultValue;
}
