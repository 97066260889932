/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState } from 'react';
import { includes } from 'lodash';

import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import { getEndpointByType } from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import FulfillmentStatusChangeModal from '../../FulfillmentStatusChangeModal';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import { getFulfillmentStatus } from '@broadleaf/admin-components/dist/oms/utils/OrderStatusUtils';
import { queueFetch } from '@broadleaf/admin-components/dist/oms/hooks/useFufillmentState/state/fulfillmentActions';
import {
  IMetadata,
  IMetadataAction
} from '@broadleaf/admin-components/dist/types/metadata';
import { IFulfillmentViewLocalState } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import RmFulfillmentAction from '../../components/RmFulfillmentAction';
import { hasPackingSlipBeenPrinted } from '../../../utils/RmFulfillmentUtils';
import { IOrderFulfillment } from '@broadleaf/admin-components/dist/types/oms';

export interface StatusChangeProps {
  actionDefinition: IMetadataAction;
  dispatch: Function;
  metadata: IMetadata;
  state: IFulfillmentViewLocalState;
}

const StatusChange: React.FC<StatusChangeProps> = ({
  actionDefinition,
  dispatch,
  metadata,
  state
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { attributes = {}, label } = actionDefinition;
  const {
    allowedStatuses = [],
    fulfillmentTypeAllowedStatuses = {},
    fulfillmentType = '',
    nextStatus,
    selectAllItems: selectAll,
    selectAllItemsMessage: selectAllMessage,
    submitLabel = label,
    submitNote
  } = attributes as Record<string, any>;
  const nextStatusEnum = getFulfillmentStatus(metadata, nextStatus);
  const handleClose = () => setIsOpen(false);
  const handleSubmit = useHandleSubmit({
    actionDefinition,
    dispatch,
    metadata,
    state,
    setIsSubmitting
  });
  const allAllowedStatuses = allowedStatuses.concat(
    fulfillmentTypeAllowedStatuses[state.data.type]
  );
  if (
    !includes(allAllowedStatuses, state.data.status) ||
    (fulfillmentType !== '' && fulfillmentType !== state.data.type)
  ) {
    return null;
  }

  const { isDisabled, actionTooltip } = getActionStatus(nextStatus, state.data);

  return (
    <>
      <RmFulfillmentAction
        color={nextStatusEnum.color}
        disabled={state.isFetching || isDisabled}
        onClick={() => setIsOpen(true)}
        tooltip={actionTooltip}
      >
        {label}
      </RmFulfillmentAction>

      {isOpen && (
        <FulfillmentStatusChangeModal
          metadata={actionDefinition}
          fulfillment={state.data}
          nextStatus={nextStatus}
          onClose={handleClose}
          onSubmit={handleSubmit}
          selectAll={selectAll}
          selectAllMessage={selectAllMessage}
          isSubmitting={isSubmitting}
          submitLabel={submitLabel}
          submitNote={submitNote}
          title={label}
        />
      )}
    </>
  );
};

function useHandleSubmit({
  actionDefinition,
  dispatch,
  metadata,
  state,
  setIsSubmitting
}) {
  const contextParams = useContextParams(metadata, state);
  const submitEndpoint = getEndpointByType(actionDefinition, 'STATUS_CHANGE');
  return useEventCallback(
    async values => {
      setIsSubmitting(true);
      const data = {
        ...values,
        reason: values.reason?.value
      };
      try {
        await request(
          {
            method: 'post',
            ...submitEndpoint,
            data
          },
          contextParams
        );
      } finally {
        setIsSubmitting(false);
        dispatch(queueFetch());
      }
    },
    [submitEndpoint, contextParams, setIsSubmitting]
  );
}

function getActionStatus(
  nextStatus: string,
  orderFulfillment: IOrderFulfillment
) {
  if (nextStatus === 'CAPTURING_PAYMENT') {
    if (!hasPackingSlipBeenPrinted(orderFulfillment)) {
      return {
        isDisabled: true,
        actionTooltip:
          'Packing Slip must be printed and expiration dates verified before order can be confirmed'
      };
    }
  }

  return {
    isDisabled: false,
    actionTooltip: ''
  };
}

export default StatusChange;
