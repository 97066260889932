/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { FC } from 'react';

export const AddListingIcon: FC<IconProps> = ({className="tw-w-20 tw-h-20"}) => {
    return (
        <img
            className={className}
            src="/RevMed-Icons-AddListing.svg"
        />
    );
}

export const ViewListingIcon: FC<IconProps> = ({className="tw-w-20 tw-h-20"}) => {
    return (
      <img
        className={className}
        src="/RevMed-Icons-ViewListing.svg"
      />
    )
}

export const BulkScanListingsIcon: FC<IconProps> = ({className="tw-w-20 tw-h-20"}) => {
    return (
      <img
        className={className}
        src="/search-barcode-icon.svg"
      />
    )
}

export const ViewOrdersIcon: FC<IconProps> = ({className="tw-w-20 tw-h-20"}) => {
    return (
        <img
            className={className}
            src="/RevMed-Icons-ViewOrders.svg"
        />
    );
}

export const ViewCustomersIcon: FC<IconProps> = ({className="tw-w-20 tw-h-20 tw-p-2"}) => {
    return (
      <img
        className={className}
        src="/RevMed-Icons-ViewCustomers.svg"
      />
    )
}
export const ViewAccountsIcon: FC<IconProps> = ({className="tw-w-20 tw-h-20 tw-p-2"}) => {
    return (
      <img
        className={className}
        src="/RevMed-Icons-ViewAccounts.svg"
      />
    )
}

type IconProps = {
    className: string;
}
